// src/hooks/useRegistradosCarousel.js
import { useState, useEffect } from 'react';

const useRegistradosCarousel = (carouselData, carouselIndex, intervalTime) => {
  const [registradosCarouselIndex, setRegistradosCarouselIndex] = useState(0);

  useEffect(() => {
    if (!carouselData || carouselData.length === 0 || !carouselData[carouselIndex]?.registrados) return;

    const interval = setInterval(() => {
      setRegistradosCarouselIndex((prevIndex) => {
        const nextIndex = prevIndex + 1;
        return nextIndex >= carouselData[carouselIndex]?.registrados.length ? 0 : nextIndex;
      });
    }, intervalTime);

    return () => clearInterval(interval);
  }, [carouselData, carouselIndex, intervalTime]);

  return { registradosCarouselIndex, setRegistradosCarouselIndex };
};

export default useRegistradosCarousel;
