import React from 'react';
import { RaceScreenProvider } from './components/RaceScreenContext';
import { linkApp } from '../../../../../generals/configuracion/variablesPublicas.jsx';
import './racescreen.css';
import useFetchRaces from './components/hooks/useFetchRaces';
import useCombinedData from './components/hooks/useCombinedData';
import useCarouselInterval from './components/hooks/useCarouselInterval';
import useRegistradosCarousel from './components/hooks/useRegistradosCarousel';
import useCheckImageExistence from './components/hooks/useCheckImageExistence';
import useRandomContent from './components/hooks/useRandomContent';

import LeftScreen from './components/pantallas/leftScreen';
import CenterScreen from './components/pantallas/CenterScreen';
import RightScreen from './components/pantallas/RightScreen';
import { frasesPredeterminadas, imagenesPredeterminadas } from './components/static/content.js';
import { useTranslation } from 'react-i18next';

const RaceScreen = () => {
  const { t } = useTranslation();

  // Hooks personalizados para obtener datos
  const { races, patrocinio, loading, error } = useFetchRaces(linkApp);
  const combinedData = useCombinedData(races, patrocinio);
  const { carouselIndex } = useCarouselInterval(combinedData, 18000);
  const { registradosCarouselIndex } = useRegistradosCarousel(combinedData, carouselIndex, 3000);
  const { imagenRegistradoSrc } = useCheckImageExistence(combinedData, carouselIndex, registradosCarouselIndex);
  const { fraseAleatoria, imagenAleatoria } = useRandomContent(frasesPredeterminadas, imagenesPredeterminadas);

  // Comprobaciones de seguridad
  if (loading) return <div>Cargando...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!combinedData || combinedData.length === 0) return <div>No hay datos disponibles</div>;

  return (
    <RaceScreenProvider>
      <div className="advertising-screens">
        {/* Pantalla izquierda */}
        <LeftScreen imagenAleatoria={imagenAleatoria} />

        {/* Pantalla central */}
        <CenterScreen
          raceData={combinedData[carouselIndex]} // Pasar solo los datos relevantes
          registradosCarouselIndex={registradosCarouselIndex}
          imagenRegistradoSrc={imagenRegistradoSrc}
          t={t}
        />

        {/* Pantalla derecha */}
       <RightScreen
          carouselData={combinedData} // Pasar todo combinedData
          carouselIndex={carouselIndex}
          t={t}
        />
      </div>
    </RaceScreenProvider>
  );
};

export default RaceScreen;
