import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import hivelogo from './../../../../../../../../assets/logoblockhorseracing/logoHive.png';
import './RaceOpenBet.css';

const RaceOpenBet = ({ carouselData, t }) => {
  const [showOfficial, setShowOfficial] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowOfficial(prevShowOfficial => !prevShowOfficial);
    }, 15000);

    return () => clearInterval(interval);
  }, [showOfficial]);

  const toggleRaceType = () => {
    setShowOfficial(prevShowOfficial => !prevShowOfficial);
  };

  return (
    <div className="race-open-bet-container">
      <div className="race-type-selector">
        <h5 className="race-type-title">
          {showOfficial ? "Oficial" : "Casual"} {t("screen.RaceOpem")}
        </h5>
        <button onClick={toggleRaceType} className="race-type-toggle-button">
          {showOfficial ? ">" : "<"}
        </button>
      </div>

      {carouselData
        .filter(data => {
          const isOfficial = data.tipo_carrera === "Oficial";
          return showOfficial ? isOfficial : !isOfficial;
        })
        .map((data, index) => (
          <div key={index} className="race-item">
            <h2>
              <a href={`./races#raceId=${data.raceid}&name=${data.name}`}>
                <span className="race-id">{data.raceid} (🏇{data.registrados.length}) 💰</span>
                <span className="race-pot">
                  {data.patrocinioData
                    ? `${data.patrocinioData.Total_Pote.toFixed(2)}`
                    : '...'}
                  <img src={hivelogo} alt="Token" className="icon" style={{ width: '1em', height: '1em' }} />
                </span>
              </a>
            </h2>
          </div>
        ))}
    </div>
  );
};

RaceOpenBet.propTypes = {
  carouselData: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default RaceOpenBet;
