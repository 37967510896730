import React from 'react';

const LeftScreen = ({ imagenAleatoria }) => {
  return (
    <div className="screen left-screen">
      <div className="neon-border-pixel-left">
        {imagenAleatoria.endsWith('.gif') ? (
          <div className="gif-container">
            <img src={imagenAleatoria} alt="Token" className="banner-pantalla-izquierda" />
          </div>
        ) : (
          <img src={imagenAleatoria} alt="Token" className="banner-pantalla-izquierda" />
        )}
      </div>
    </div>
  );
};

export default LeftScreen;
