import React from 'react';
import hivelogo from './../../../../../../../../assets/logoblockhorseracing/logoHive.png';

const CenterScreen = ({ raceData, registradosCarouselIndex, imagenRegistradoSrc, t }) => {
  if (!raceData) return null;

  return (
    <div className="screen center-screen" key={raceData.raceid}>
      <div className="neon-border">
        <h1>
          {raceData.raceid} (🏇{raceData.registrados?.length || 0})
        </h1>

        <div className="carousel-registrados">
          <div className="actives">
            <button className="pantalla-neon-boton">
              <a href={`./races#raceId=${raceData.raceid}&name=${raceData.name}`}>
                {t('pPixel.llevame')}
              </a>
            </button>

            <div className="OwnerCard">
              <p>👑{raceData.registrados_owners?.[registradosCarouselIndex]}</p>
              <p>{raceData.registrados?.[registradosCarouselIndex]}</p>
            </div>

            <div className="imagen-caballos">
              <img src={imagenRegistradoSrc} alt="Caballo registrado" />
              <h3>
                💰{raceData.patrocinioData?.Total_Pote?.toFixed(2) || '...'}
                <img src={hivelogo} alt="Token" className="icon" style={{ width: '0.75em', height: '0.75em' }} />
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CenterScreen;
