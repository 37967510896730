import React, { useState } from 'react';
import MarketBar from "./components/navbar_market/market_bar.jsx"
import MarketBallos from"./components/balloMarket.jsx"
import NftFood from"./components/foodMarket.jsx"
import NftImplementos from"./components/implementosMarket.jsx"

function Dashboard() {
  const [currentRoute, setCurrentRoute] = useState('/market');

  const handleNavClick = (route) => {
    setCurrentRoute(route);
  }

  let componentToRender;
  switch(currentRoute) {
    case '/marketballos':
     componentToRender = <MarketBallos ClassName="active"/>;
      break;
    case '/food':
      componentToRender = <NftFood ClassName="active"/>;
      break;
    case '/implementos':
      componentToRender = <NftImplementos ClassName="active"/>;
      break;
    default:
      componentToRender = <NftFood ClassName="active"/>;
      break;
  }

  return (
    <div>
      <MarketBar handleNavClick={handleNavClick}/>
      {componentToRender}
    </div>
  )
}

export default Dashboard;

