import gift_deposita  from './../../../../../../../../assets/gif/instructivo/depositarWalletIngles.gif';
import gift_depositaEspa  from './../../../../../../../../assets/gif/instructivo/depositarWalletEspa.gif';
import banner1 from "./../../../../../../../../assets/banner/pantalla_izq/banner1iG.png";
import raven from "./../../../../../../../../assets/banner/pantalla_izq/raven1.gif";
import nftgamela from"./../../../../../../../../assets/banner/pantalla_izq/nftgamela.gif";
import banner_wellcome from "./../../../../../../../../assets/banner/pantalla_izq/wellcome.gif";
import banner3 from "./../../../../../../../../assets/banner/dashboard/banner1.png";
import hive_games from"./../../../../../../../../assets/banner/pantalla_izq/Game_Hive_Mundo.gif";
import bannerNS from"./../../../../../../../../assets/banner/pantalla_izq/banner-NS.jpg";

// Array con varias frases predeterminadas
export const frasesPredeterminadas = [
  'Gracias por tu apoyo', 'Thanks for your support',
  'La propiedad de un caballo de carrera en tus manos',  'Racehorse ownership in your hands',
  'BHR mejora tus probabilidades estadísticas',  'BHR improves your statistical odds',
  'La emoción de las carreras',  'The thrill of the races',
  'Clicks en las ? amarilla para ampliar el punto',
  'Eres el proximo en ganar mas de 30X en el sistema de patrocinio?',
  'Patrocina y gana como nunca lo habías experimentado',  'Sponsor and win like you ve never experienced before',
  'Patrocina un Nft y recoge beneficio si este llega dentro de los primeros 4 puestos',
  
  
];

export const imagenesPredeterminadas = [
   gift_deposita,
   gift_deposita,
     raven,
   hive_games,
   nftgamela,
   gift_depositaEspa,
   gift_depositaEspa,
   banner1,
   bannerNS,
   banner3,
   banner_wellcome,
  
  
];