import React, { useState, useEffect } from "react";
import './food.css';
import Loader from "./../../../../generals/loders/Loader.jsx";
import UtilityModal from "./food/Utility_Modal";
import MyUtilityModal from "./food/MyUtility_Modal";
import useMarketNfts from '../../../../../hooks/useMarketNfts.js'; // Import the generic hook
import useMyMarketNfts from '../../../../../hooks/useMyMarketNfts.js'; // Import the generic hook

import { handleBuyOrder, handleCancelOrder } from './food/handles.jsx';

export default function Food() {
  const [showUtilityModal, setShowUtilityModal] = useState(true);
  const [showMyUtilityModal, setShowMyUtilityModal] = useState(false);

  // Use the food hook

  // Use the generic hook for different NFT types
  const { nfts: tack_nft, isLoaded: isTackLoaded } = useMarketNfts("tack");
  const { nfts: recover_nft, isLoaded: isRecoverLoaded } = useMarketNfts("recover");
  const { nfts: booster_nft, isLoaded: isBoosterLoaded } = useMarketNfts("booster");
  const { nfts: breeding_nft, isLoaded: isBreedingLoaded } = useMarketNfts("breeding");


 const { nfts: mytack_nft, isLoaded: isMyTackLoaded } = useMyMarketNfts("tack");
  const { nfts: myrecover_nft, isLoaded: isMyRecoverLoaded } = useMyMarketNfts("recover");
  const { nfts: mybooster_nft, isLoaded: isMyBoosterLoaded } = useMyMarketNfts("booster");
  const { nfts: mybreeding_nft, isLoaded: isMyBreedingLoaded } = useMyMarketNfts("breeding");


  const handleMarketClick = () => {
    setShowUtilityModal(true);
    setShowMyUtilityModal(false);
  };

  const handleMyItemsClick = () => {
    setShowMyUtilityModal(true);
    setShowUtilityModal(false);
  };

  // Combine the loading states for display purposes
  const isAllLoaded = isTackLoaded && isRecoverLoaded && isBoosterLoaded && isBreedingLoaded;

  return (
    <>
      <div className="wrapp">
        <div className="container-market-implementos">

              {tack_nft.length === 0 && isAllLoaded === false ? (
          <Loader />
        ) : (
          <button onClick={handleMarketClick} className="Nav-Market"><h1>Market</h1></button>
        )}

        {isAllLoaded === false ? (
          <></>
        ) : (
          <button onClick={handleMyItemsClick} className="Nav-Market"><h1>Your Items</h1></button>
        )}


           {showUtilityModal && (
            <>
        
          <UtilityModal nfts={tack_nft} onBuy={handleBuyOrder} nft_tipo="Tacks"/>
           <UtilityModal nfts={recover_nft} onBuy={handleBuyOrder}  nft_tipo="Recover"/>
           <UtilityModal nfts={booster_nft} onBuy={handleBuyOrder}  nft_tipo="Boosters"/>
           <UtilityModal nfts={breeding_nft} onBuy={handleBuyOrder}  nft_tipo="Breeding"/>
        

       
        </>
        )}

        {showMyUtilityModal && (


           <>
           <MyUtilityModal nfts={mytack_nft} onBuy={handleCancelOrder} nft_tipo="Tacks"/>
           <MyUtilityModal nfts={myrecover_nft} onBuy={handleCancelOrder}  nft_tipo="Recover"/>
           <MyUtilityModal nfts={mybooster_nft} onBuy={handleCancelOrder}  nft_tipo="Boosters"/>
           <MyUtilityModal nfts={mybreeding_nft} onBuy={handleCancelOrder}  nft_tipo="Breeding"/>

</>
)}
        </div>
      </div>
    </>
  );
}
