import React from "react";
import './cofreclaim.css'
import { useTranslation } from 'react-i18next';

const images = {
  1: ['https://www.blockhorseracing.com/image/especial_nfts/100buds.png'],
  2: ['https://www.blockhorseracing.com/image/especial_nfts/votecuration15.webp'],

  3: ['https://www.blockhorseracing.com/image/especial_nfts/30bhrt.png'],
  4: ['https://www.blockhorseracing.com/image/especial_nfts/votecuration20.webp'],
  

  5: ['https://www.blockhorseracing.com/image/especial_nfts/001bhr.png'],
  6: ['https://www.blockhorseracing.com/image/especial_nfts/votecuration20to50.webp'],
  

  7: ['https://www.blockhorseracing.com/image/especial_nfts/2000buds.png'],
  8: ['https://www.blockhorseracing.com/image/especial_nfts/votecuration50.webp'],
  

  9: ['https://www.blockhorseracing.com/image/especial_nfts/300bhrt.png'],
  10: ['https://www.blockhorseracing.com/image/especial_nfts/votecuration50to100.webp'],
  

  11: ['https://www.blockhorseracing.com/image/especial_nfts/01bhr.png'],
  12: ['https://www.blockhorseracing.com/image/utility_nfts/carrot.png'],
  13: ['https://www.blockhorseracing.com/image/utility_nfts/aple.png'],
  14: ['https://www.blockhorseracing.com/image/utility_nfts/carrot.png','https://www.blockhorseracing.com/image/utility_nfts/aple.png'],
  15:['https://www.blockhorseracing.com/image/utility_nfts/carrot.png','https://www.blockhorseracing.com/image/utility_nfts/carrot.png'],
  16:['https://www.blockhorseracing.com/image/utility_nfts/aple.png','https://www.blockhorseracing.com/image/utility_nfts/aple.png'],
  17:['https://www.blockhorseracing.com/image/especial_nfts/02bhr.png'],
  18:['https://www.blockhorseracing.com/image/utility_nfts/hay.png'],
  19:['https://www.blockhorseracing.com/image/utility_nfts/alfa.png'],
  20:['https://www.blockhorseracing.com/image/utility_nfts/aple.png','https://www.blockhorseracing.com/image/utility_nfts/aple.png','https://www.blockhorseracing.com/image/utility_nfts/carrot.png','https://www.blockhorseracing.com/image/utility_nfts/carrot.png'],
  21:['https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/carrot.png','https://www.blockhorseracing.com/image/utility_nfts/carrot.png'],
  22:['https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/aple.png','https://www.blockhorseracing.com/image/utility_nfts/aple.png'],
  23:['https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/carrot.png','https://www.blockhorseracing.com/image/utility_nfts/carrot.png'],
  24:['https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/aple.png','https://www.blockhorseracing.com/image/utility_nfts/aple.png'],
  25:['https://www.blockhorseracing.com/image/especial_nfts/1000bhrt.png'],
  26:['https://www.blockhorseracing.com/image/magic_nfts/add/add_10_races.png'],
  27:['https://www.blockhorseracing.com/image/magic_nfts/recover/parasiticides.png'],
  28:['https://www.blockhorseracing.com/image/utility_nfts/imp/saddles.png'],
  29:['https://www.blockhorseracing.com/image/especial_nfts/commonmagicchest.png'],
  30:['https://www.blockhorseracing.com/image/especial_nfts/raremagicchest.png'],
  31:['https://www.blockhorseracing.com/image/especial_nfts/epicmagicchest.png'],
  32:['https://www.blockhorseracing.com/image/especial_nfts/commonmagickey.png'],
  33:['https://www.blockhorseracing.com/image/especial_nfts/raremagickey.png'],
  34:['https://www.blockhorseracing.com/image/especial_nfts/epicmagickey.png'],
  35:['https://www.blockhorseracing.com/image/especial_nfts/commonchest.png'],
  36:['https://www.blockhorseracing.com/image/especial_nfts/rarechest.png'],
  37:['https://www.blockhorseracing.com/image/especial_nfts/epicchest.png'],
  38:['https://www.blockhorseracing.com/image/especial_nfts/commonkey.png'],
  39:['https://www.blockhorseracing.com/image/especial_nfts/rarekey.png'],
  40:['https://www.blockhorseracing.com/image/especial_nfts/epickey.png'],
  };





function getImages(valor) {
  return images[valor] || [];
      
}

function Divs({ cofresProcesando }) {
  const { t } = useTranslation();
  return (
    <>
      {Object.keys(cofresProcesando).map((divId) => {
         
        const valor = cofresProcesando[divId];

        const images = getImages(valor);
        const numeroCofre = parseInt(divId) + 1;

        return (<>
          <div key={divId} className="modales-conten">
            <h3>{t('cofres.cofres')} {numeroCofre -1}  </h3>
            </div>
            <div key={divId} className="modales-conten">
            {images.map((rutaImagen) => (
              <img key={rutaImagen} src={rutaImagen} alt="" />
            ))}
          </div>
          </>
        );
      })}
    </>
  );
}

function Modal({ isOpen, onClose, cofresProcesando }) {
  console.log('Modal renderizado');
  console.log("Y",isOpen);
  
  if (!isOpen) return null;

  return (
    <div className="modales">
      <div className="modales-content">
        <button onClick={onClose}><h1>X</h1></button>
        <Divs cofresProcesando={cofresProcesando} />
      </div>
    </div>
  );
}

export default Modal;



