import React, { useState } from 'react';
import NavbarNft from "./components/navbar_nft/nav_bar_nft.jsx"
import Ballo from"./../../../fetch/Caballos.jsx"
import TicketModule from"./../../../games/TicketModule.jsx"

import Cofres from"./../../../fetch_nft/cofres.jsx"
import CofresPatro from"./../../../fetch_nft/cofres_patrocinio.jsx"
// import Cofresespeciales from"./../../../fetch_nft/cofresespeciales.jsx"
import Cofresmagicos from"./../../../fetch_nft/cofresmagicos.jsx"
import NftU from"./../../../fetch_nft/utility_nft.jsx"
import NftE from"./../../../fetch_nft/implementos_nft.jsx"
import Wallet from'./Wallet.jsx';
import CountdownClock from './components/temporada/temporada.jsx' 
import RaceScreen from './components/race_open_bets/RaceScreenBets.jsx'
// import EncuestasModal from './components/encuestas/EncuestasModal.jsx'
import "./dashboard.css"


function Dashboard() {
  const [currentRoute, setCurrentRoute] = useState('/caballos');

  const handleNavClick = (route) => {
    setCurrentRoute(route);
  }

  let componentToRender;
  switch(currentRoute) {
    
    case '/games':
      componentToRender = <div className="containerDash">{/*<EncuestasModal/> */} <TicketModule/></div>;
      break;
    case '/caballos':
      componentToRender = <div className="containerDash"><CountdownClock/>{/*<EncuestasModal/> */}<RaceScreen/> <Ballo/></div>;
      break;
    case '/nft_u':
      componentToRender =<div className="container-inventario-Alimentos"><Cofres/>, <NftU /></div>;
      break;
    case '/nft_e':
      componentToRender =<div className="container-inventario-Implementos"><Cofresmagicos/>, <NftE /></div>;
      break;
    default:
      componentToRender = <Ballo />;
      break;
  }

  return (
    <div>
     <Wallet/>
      <NavbarNft handleNavClick={handleNavClick}/>
      {componentToRender}
    </div>
  )
}

export default Dashboard;

