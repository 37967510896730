// marketHandlers.js

export const handleBuyOrder = (selectedItems) => {
  console.log("Buy Order:", selectedItems);

  let username = localStorage.getItem("user");
  const ids = selectedItems.map((item) => item.id);
  console.log("IDS", ids);

  const total = selectedItems.reduce((sum, item) => sum + parseFloat(item.price), 0);
  console.log("totales", total);

  const symbol = selectedItems.length > 0 ? selectedItems[0].symbol : '';
  console.log("symbol", symbol);

  if (window.hive_keychain) {
    const keychain = window.hive_keychain;

    const customJson = [
      {
        contractName: "nftmarket",
        contractAction: "buy",
        contractPayload: {
          symbol: "BHREQT",
          nfts: ids,
          expPrice: total.toFixed(3),
          expPriceSymbol: symbol,
          marketAccount: "bhr-market"
        }
      }
    ];

    keychain.requestCustomJson(username, 'ssc-mainnet-hive', 'Active', JSON.stringify(customJson), 'Buy NFTs', response => {
      console.log('Response from Hive Keychain:', response);
    });
  } else {
    alert('You do not have hive keychain installed');
  }
};

export const handleCancelOrder = (selectedItems) => {
  console.log("Cancel Order:", selectedItems);

  let username = localStorage.getItem("user");
  const ids = selectedItems.map((item) => item.id);
  console.log("IIDS", ids);

  if (window.hive_keychain) {
    const keychain = window.hive_keychain;

    const customJson = [
      {
        contractName: "nftmarket",
        contractAction: "cancel",
        contractPayload: {
          symbol: "BHREQT",
          nfts: ids
        }
      }
    ];

    keychain.requestCustomJson(username, 'ssc-mainnet-hive', 'Active', JSON.stringify(customJson), 'Cancel Sell', response => {
      console.log('Response from Hive Keychain:', response);
    });
  } else {
    alert('You do not have hive keychain installed');
  }
};

export const handleMarketClick = (setShowUtilityModal, setShowMyUtilityModal) => {
  setShowUtilityModal(true);
  setShowMyUtilityModal(false);
};

export const handleMyItemsClick = (setShowUtilityModal, setShowMyUtilityModal) => {
  setShowMyUtilityModal(true);
  setShowUtilityModal(false);
};
