import { useState, useEffect } from 'react';

const useRandomContent = (frases, imagenes) => {
  const [fraseAleatoria, setFraseAleatoria] = useState('');
  const [imagenIndex, setImagenIndex] = useState(0);

  useEffect(() => {
    const cambiarFrase = () => {
      const indiceAleatorio = Math.floor(Math.random() * frases.length);
      setFraseAleatoria(frases[indiceAleatorio]);
    };

    const cambiarImagen = () => {
      setImagenIndex((prevIndex) => (prevIndex + 1) % imagenes.length);
    };

    cambiarFrase();
    cambiarImagen();

    const fraseInterval = setInterval(cambiarFrase, 5000);
    const imagenInterval = setInterval(cambiarImagen, 15000);

    return () => {
      clearInterval(fraseInterval);
      clearInterval(imagenInterval);
    };
  }, [frases, imagenes]);

  return { fraseAleatoria, imagenAleatoria: imagenes[imagenIndex] };
};

export default useRandomContent;
