// src/hooks/useMarketNfts.js
import { useState, useEffect } from 'react';
import axiosInstance from '../services/axiosConfig'; // Adjust the path as needed
import {rpcNode} from "./../components/generals/configuracion/variablesPublicas.jsx"


const useMarketNfts = (groupingType) => {
  const [nfts, setNfts] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const getMarketNfts = async () => {
      const query = { 'grouping.type': groupingType };

      const body = JSON.stringify([
        {
          method: "find",
          jsonrpc: "2.0",
          params: {
            contract: 'nftmarket',
            table: "BHREQTsellBook",
            query: query,
            limit: 1000,
            offset: 0,
            indexes: [],
          },
          id: 1,
        },
      ]);

      try {
        const response = await axiosInstance.post(rpcNode, body);
        const data = response.data[0].result;
        console.log(`DATA DE MARKET (${groupingType}):`, data);

        let groupedNfts = {};

        data.forEach((item) => {
          const { _id: identidad, nftId, grouping: { name: groupingName, type: groupingTipo }, price, account, priceSymbol: symbol, fee } = item;

          const nft = { identidad, nftId, account, name: groupingName, tipo: groupingTipo, price, symbol, fee };

          if (groupingName in groupedNfts) {
            groupedNfts[groupingName].push(nft);
          } else {
            groupedNfts[groupingName] = [nft];
          }
        });

         // Correctly set the state with grouped data
         setNfts(groupedNfts);

      } catch (error) {
        console.error(`Error fetching ${groupingType} NFTs:`, error);
      } finally {
        setIsLoaded(true);
      }
    };

    getMarketNfts();
  }, [groupingType]); // Dependency array includes groupingType

  return { nfts, isLoaded };
};

export default useMarketNfts;
