// src/hooks/useFetchRaces.js
import { useState, useEffect } from 'react';

const useFetchRaces = (linkApp) => {
  const [races, setRaces] = useState([]);
  const [patrocinio, setPatrocinio] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRaces = async () => {
      try {
        const response = await fetch(`${linkApp}/race`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ status_superior: "abierta" }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log("data Race", data);
        const racing = data.body;
        const objetosFiltrados = racing.filter(objeto => objeto.registrados.length > 1);
        setRaces(objetosFiltrados);

        const raceIds = objetosFiltrados.map(objeto => objeto.raceid);
        try {
          const raceIdParams = raceIds.join(',');
          const patrocinioResponse = await fetch(`${linkApp}/bets?race=${raceIdParams}`);
          if (!patrocinioResponse.ok) {
            throw new Error(`HTTP error! Status: ${patrocinioResponse.status}`);
          }

          const dataso = await patrocinioResponse.json();
          setPatrocinio(dataso.body);
        } catch (patrocinioError) {
          console.error('Error al obtener información adicional para las carreras:', patrocinioError);
          setError(patrocinioError);
        }
      } catch (fetchError) {
        console.error('Error al obtener las carreras:', fetchError);
        setError(fetchError);
      } finally {
        setLoading(false);
      }
    };

    fetchRaces();
  }, [linkApp]);

  return { races, patrocinio, loading, error };
};

export default useFetchRaces;
