// src/hooks/useCombinedData.js
import { useState, useEffect } from 'react';

const useCombinedData = (races, patrocinio) => {
  const [combinedData, setCombinedData] = useState([]);

  useEffect(() => {
    const combinedArray = races.map((race) => {
      const matchingPatrocinio = Array.isArray(patrocinio) ? patrocinio.find((item) => item.race === race.raceid) : null;

      return {
        ...race,
        patrocinioData: matchingPatrocinio,
      };
    });

    setCombinedData(combinedArray);
  }, [races, patrocinio]);

  return combinedData;
};

export default useCombinedData;
