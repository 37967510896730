import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { rpcNode } from "./../generals/configuracion/variablesPublicas.jsx";


 import './components/utility_nfts.css'
import iconNegocios from'./../../assets/home-broker-dealer-icon.svg'

import Loader from"./../generals/loders/Loader.jsx"


import MyUtilityModal from "./modalinventario/food/foodModal.jsx";
import NoNFTModal from "./modalinventario/non_nfts/noNftModal.jsx";
import VoteModal from "./modalinventario/vote_curation/VoteModal.jsx";
import { useFetchUtilityNfts } from './modalinventario/food/utils/fetchUtilityNfts.jsx'; // Import the custom hook




let initialValue=false




  export default function AjaxHooks() {
  // const [utility_Nfts, setUtility_Nfts] = useState([]);
   const [non_Nfts, setNon_Nfts] = useState([]);
   const [vote_Nfts, setVote_Nfts] = useState([]);
  const [isLoaded, setisLoaded] = useState(false);


const { utility_Nfts, isLoaded_food } = useFetchUtilityNfts(rpcNode,localStorage.getItem("user"), "food");








useEffect(() => {

 const getNo_Nfts = async (url) => {

      let config = {
      headers: {
         'Content-Type': 'application/json',
         'Access-Control-Allow-Origin': '*',
      },
    };

    let query={}
      query.account = localStorage.getItem("user");
      query['properties.type']="equine";
      


    let body = JSON.stringify([
      {
        method: "find",
        jsonrpc: "2.0",
        params: {
          contract: 'nft',
          table: "BHREQTinstances",
          query: query,
          limit: 50,
          offset: 0,
          indexes: [],
        },
        id: 1,
      },
    ]);

     await axios.post(url, body, config).then((data) => {
        console.log("DATES",data)
      data =data.data[0].result;
   
console.log("Generaciona", data);





     let groupedUtilityNfts = {};

        data.forEach((data) => {
          let identidad = data._id;
          let nft_id = data.nftId;
          let groupingName = data.properties.name;
          let groupingTipo = data.properties.type;
        
        
          let MyUtility_Nft = {
            identidad: identidad,
            nftId: nft_id,
            name: groupingName,
            tipo: groupingTipo,
          };

          if (groupingName in groupedUtilityNfts) {
            groupedUtilityNfts[groupingName].push(MyUtility_Nft);
          } else {
            groupedUtilityNfts[groupingName] = [MyUtility_Nft];
          }
        });

 setNon_Nfts(groupedUtilityNfts);


      }).catch((err)=>{  
           console.log("error fetching",err);
                } 
         ).finally(()=>{

          //console.log("se ejecuta finally");
          setisLoaded(true)
      })
    
   };
    getNo_Nfts(rpcNode);

  },[]);



useEffect(() => {

 const getVote_Nfts = async (url) => {

      let config = {
      headers: {
         'Content-Type': 'application/json',
         'Access-Control-Allow-Origin': '*',
      },
    };

    let query={}
      query.account = localStorage.getItem("user");
      query['properties.type']="vote";
      


    let body = JSON.stringify([
      {
        method: "find",
        jsonrpc: "2.0",
        params: {
          contract: 'nft',
          table: "BHREQTinstances",
          query: query,
          limit: 50,
          offset: 0,
          indexes: [],
        },
        id: 1,
      },
    ]);

     await axios.post(url, body, config).then((data) => {
        console.log("DATES",data)
      data =data.data[0].result;
   
console.log("Generaciona", data);





     let groupedUtilityNfts = {};

        data.forEach((data) => {
            let identidad = data._id;
          let nft_id = data.nftId;
          let groupingName = data.properties.name;
          let groupingTipo = data.properties.type;
          let groupingImage =data.properties.image;
        
        
          let MyUtility_Nft = {
            identidad: identidad,
            nftId: nft_id,
            name: groupingName,
            tipo: groupingTipo,
            image: groupingImage,
          };

          if (groupingName in groupedUtilityNfts) {
            groupedUtilityNfts[groupingName].push(MyUtility_Nft);
          } else {
            groupedUtilityNfts[groupingName] = [MyUtility_Nft];
          }
        });

 setVote_Nfts(groupedUtilityNfts);


      }).catch((err)=>{  
           console.log("error fetching",err);
                } 
         ).finally(()=>{

          //console.log("se ejecuta finally");
          setisLoaded(true)
      })
    
   };
    getVote_Nfts(rpcNode);

  },[]);


  return (
    <>
      
      <div className="container">
        

      {((utility_Nfts.length === 0) &&(isLoaded===false)) ? (
        <Loader/>

      ) : (
       

        //   <Utility_Nft  key={el.index}  tipo= {el.tipo} identidad={el.identidad} minteo={el.id}
        //   name={el.name} 
        // Imagen={el.imagen}   />
         <>
         <h2>Food</h2>
     <MyUtilityModal nfts={utility_Nfts}  />

<h2>Non -Nfts</h2>
     <NoNFTModal nfts={non_Nfts}  />
        
<h2>Vote -Nfts</h2>
      
     <VoteModal nfts={vote_Nfts}  />
        

</>
         
      )}
        </div>
    </>
  );
}

