 // const linkApp ='http://localhost:4000'; 
 const linkApp = '';
 const consoleEnabled = false; // Renombrado


 const rpcNode="https://api.hive-engine.com/rpc/contracts";

// Exportar los elementos como nombrados
export { consoleEnabled as consol, linkApp, rpcNode };



