import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: '', // Replace with your API base URL
  timeout: 90000,
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosInstance.interceptors.request.use(
  config => {
    // You can modify the request config here (e.g., add authentication tokens)
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      console.log('Data:', error.response.data);
      console.log('Status:', error.response.status);
      console.log('Headers:', error.response.headers);
    } else if (error.request) {
      console.log('Request:', error.request);
    } else {
      console.log('Error:', error.message);
    }
    console.log('Config:', error.config);
    return Promise.reject(error);
  }
);

export default axiosInstance;
