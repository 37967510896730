// src/hooks/useCarouselInterval.js
import { useState, useEffect } from 'react';

const useCarouselInterval = (carouselData, intervalTime) => {
  const [carouselIndex, setCarouselIndex] = useState(0);

  useEffect(() => {
    if (!carouselData || carouselData.length === 0) return; // Prevent interval if data is empty

    const interval = setInterval(() => {
      setCarouselIndex((prevIndex) => (prevIndex + 1) % carouselData.length);
    }, intervalTime);

    return () => clearInterval(interval);
  }, [carouselData, intervalTime]); // Depend on carouselData and intervalTime

  return { carouselIndex, setCarouselIndex };
};

export default useCarouselInterval;
