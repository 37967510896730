import { useState, useEffect } from 'react';
import axios from 'axios';

export const useFetchUtilityNfts = (url) => {  // Use a descriptive name
    const [utility_Nfts, setUtility_Nfts] = useState([]);
    const [isLoaded_food, setisLoaded_food] = useState(false);

    useEffect(() => {
        const getUtility_Nfts = async () => {
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
            };

            let query = {};
            query.account = localStorage.getItem("user");
            query['properties.type'] = "food";  //Consider making "food" a parameter to the hook


            let body = JSON.stringify([
                {
                    method: "find",
                    jsonrpc: "2.0",
                    params: {
                        contract: 'nft',
                        table: "BHREQTinstances",
                        query: query,
                        limit: 500,
                        offset: 0,
                        indexes: [],
                    },
                    id: 1,
                },
            ]);

            try {
                const response = await axios.post(url, body, config);
                const data = response.data[0].result;


                let groupedUtilityNfts = {};

                data.forEach((data) => {
                    let identidad = data._id;
                    let nft_id = data.nftId;
                    let groupingName = data.properties.name;
                    let groupingTipo = data.properties.type;


                    let MyUtility_Nft = {
                        identidad: identidad,
                        nftId: nft_id,
                        name: groupingName,
                        tipo: groupingTipo,
                    };

                    if (groupingName in groupedUtilityNfts) {
                        groupedUtilityNfts[groupingName].push(MyUtility_Nft);
                    } else {
                        groupedUtilityNfts[groupingName] = [MyUtility_Nft];
                    }
                });

                setUtility_Nfts(groupedUtilityNfts);
            } catch (err) {
                console.error("error fetching", err);
            } finally {
                setisLoaded_food(true);
            }

        };

        getUtility_Nfts();

    }, [url]);  // Add url as dependency so effect reruns if url change
//ADD HERE, the dependency that changes

    return { utility_Nfts, isLoaded_food }; // Return both values
};
