import React, { useState, useEffect } from "react";
import axios from 'axios';



 import './components/utility_nfts.css'
import iconNegocios from'./../../assets/home-broker-dealer-icon.svg'

import Loader from"./../generals/loders/Loader.jsx"


import MyUtilityModal from "./modalinventarioM/implementoModal.jsx";
import { rpcNode } from "./../generals/configuracion/variablesPublicas.jsx";



export default function Food() {
  const [MyUtility_Nfts, setMyUtility_Nfts] = useState([]);
  const [Utility_Nfts, setUtility_Nfts] = useState([]);


  const [isLoaded, setisLoaded] = useState(false);
  const [isMyLoaded, setisMyLoaded] = useState(false);
  const [showUtilityModal, setShowUtilityModal] = useState(false);
  const [showMyUtilityModal, setShowMyUtilityModal] = useState(false);
































useEffect(() => {
  const getMyTacks = async () => {
    const groupingTypes = ["tack"];

    for (let i = 0; i < groupingTypes.length; i++) {
      const groupingType = groupingTypes[i];
      
      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      };

      // let query = {};
      // query['grouping.type'] = groupingType;
      // query.account = localStorage.getItem("user");

  let query={}
      query.account = localStorage.getItem("user");
      query['properties.type']="tack";
      
      


    let body = JSON.stringify([
      {
        method: "find",
        jsonrpc: "2.0",
        params: {
          contract: 'nft',
          table: "BHREQTinstances",
          query: query,
          limit: 100,
          offset: 0,
          indexes: [],
        },
        id: 1,
      },
    ]);

      try {
        const response = await axios.post(rpcNode, body, config);
        const data = response.data[0].result;

        console.log(`DATA DE MARKET para ${groupingType}`, data);

        let groupedUtilityNfts = {};

        data.forEach((data) => {
          let identidad = data._id;
          let nft_id = data.nftId;
          let groupingName = data.properties.name;
          let groupingTipo = data.properties.type;
          let Imagen=data.properties.image;
        

          let MyUtility_Nft = {
            identidad: identidad,
            nftId: nft_id,
        
            name: groupingName,
            tipo: groupingTipo,
                imagen: Imagen,
       
          };





          if (groupingName in groupedUtilityNfts) {
            groupedUtilityNfts[groupingName].push(MyUtility_Nft);
          } else {
            groupedUtilityNfts[groupingName] = [MyUtility_Nft];
          }
        });

      setMyUtility_Nfts(prevMyUtilityNfts => {
        const updatedMyUtilityNfts = { ...prevMyUtilityNfts };

        // Agregar la nueva información al objeto groupedUtilityNfts
        Object.keys(groupedUtilityNfts).forEach(groupingName => {
          if (groupingName in updatedMyUtilityNfts) {
            updatedMyUtilityNfts[groupingName] = [
              ...updatedMyUtilityNfts[groupingName],
              ...groupedUtilityNfts[groupingName]
            ];
          } else {
            updatedMyUtilityNfts[groupingName] = groupedUtilityNfts[groupingName];
          }
        });

        // Devolver el estado actualizado
        return updatedMyUtilityNfts;
      });

      } catch (error) {
        console.log(`Error fetching para ${groupingType}`, error);
      }
    }

    // Puedes realizar alguna acción adicional después de obtener todos los datos
    // setisMyLoaded(true);
  };

  getMyTacks();
}, []);






useEffect(() => {
  const getMyRecover = async () => {


  
      
      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      };

      // let query = {};
      // query['grouping.type'] = groupingType;
      // query.account = localStorage.getItem("user");

  let query={}
      query.account = localStorage.getItem("user");
      query['properties.type']="recover";
      
      


    let body = JSON.stringify([
      {
        method: "find",
        jsonrpc: "2.0",
        params: {
          contract: 'nft',
          table: "BHREQTinstances",
          query: query,
          limit: 100,
          offset: 0,
          indexes: [],
        },
        id: 1,
      },
    ]);

      try {
        const response = await axios.post(rpcNode, body, config);
        const data = response.data[0].result;

      
        let groupedUtilityNfts = {};

        data.forEach((data) => {
          let identidad = data._id;
          let nft_id = data.nftId;
          let groupingName = data.properties.name;
          let groupingTipo = data.properties.type;
          let Imagen=data.properties.image;
        

          let MyUtility_Nft = {
            identidad: identidad,
            nftId: nft_id,
        
            name: groupingName,
            tipo: groupingTipo,
                imagen: Imagen,
       
          };





          if (groupingName in groupedUtilityNfts) {
            groupedUtilityNfts[groupingName].push(MyUtility_Nft);
          } else {
            groupedUtilityNfts[groupingName] = [MyUtility_Nft];
          }
        });

      setMyUtility_Nfts(prevMyUtilityNfts => {
        const updatedMyUtilityNfts = { ...prevMyUtilityNfts };

        // Agregar la nueva información al objeto groupedUtilityNfts
        Object.keys(groupedUtilityNfts).forEach(groupingName => {
          if (groupingName in updatedMyUtilityNfts) {
            updatedMyUtilityNfts[groupingName] = [
              ...updatedMyUtilityNfts[groupingName],
              ...groupedUtilityNfts[groupingName]
            ];
          } else {
            updatedMyUtilityNfts[groupingName] = groupedUtilityNfts[groupingName];
          }
        });

        // Devolver el estado actualizado
        return updatedMyUtilityNfts;
      });

      } catch (error) {
        console.log(`Error fetching para`, error);
      }
 
  };

  getMyRecover();
}, []);




useEffect(() => {
  const getMyEspecial = async () => {


  
      
      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      };

      
  let query={}
      query.account = localStorage.getItem("user");
      query['properties.type']="especial";
      
      


    let body = JSON.stringify([
      {
        method: "find",
        jsonrpc: "2.0",
        params: {
          contract: 'nft',
          table: "BHREQTinstances",
          query: query,
          limit: 100,
          offset: 0,
          indexes: [],
        },
        id: 1,
      },
    ]);

      try {
        const response = await axios.post(rpcNode, body, config);
        const data = response.data[0].result;

      
        let groupedUtilityNfts = {};

        data.forEach((data) => {
          let identidad = data._id;
          let nft_id = data.nftId;
          let groupingName = data.properties.name;
          let groupingTipo = data.properties.type;
          let Imagen=data.properties.image;
        

          let MyUtility_Nft = {
            identidad: identidad,
            nftId: nft_id,
        
            name: groupingName,
            tipo: groupingTipo,
            imagen: Imagen,
            typeimg:".gif",
          };





          if (groupingName in groupedUtilityNfts) {
            groupedUtilityNfts[groupingName].push(MyUtility_Nft);
          } else {
            groupedUtilityNfts[groupingName] = [MyUtility_Nft];
          }
        });

      setMyUtility_Nfts(prevMyUtilityNfts => {
        const updatedMyUtilityNfts = { ...prevMyUtilityNfts };

        // Agregar la nueva información al objeto groupedUtilityNfts
        Object.keys(groupedUtilityNfts).forEach(groupingName => {
          if (groupingName in updatedMyUtilityNfts) {
            updatedMyUtilityNfts[groupingName] = [
              ...updatedMyUtilityNfts[groupingName],
              ...groupedUtilityNfts[groupingName]
            ];
          } else {
            updatedMyUtilityNfts[groupingName] = groupedUtilityNfts[groupingName];
          }
        });

        // Devolver el estado actualizado
        return updatedMyUtilityNfts;
      });

      } catch (error) {
        console.log(`Error fetching para`, error);
      }
 
  };

  getMyEspecial();
}, []);







useEffect(() => {
  const getMyBooster = async () => {


  
      
      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      };

      // let query = {};
      // query['grouping.type'] = groupingType;
      // query.account = localStorage.getItem("user");

  let query={}
      query.account = localStorage.getItem("user");
      query['properties.type']="booster";
      
      


    let body = JSON.stringify([
      {
        method: "find",
        jsonrpc: "2.0",
        params: {
          contract: 'nft',
          table: "BHREQTinstances",
          query: query,
          limit: 100,
          offset: 0,
          indexes: [],
        },
        id: 1,
      },
    ]);

      try {
        const response = await axios.post(rpcNode, body, config);
        const data = response.data[0].result;

      
        let groupedUtilityNfts = {};

        data.forEach((data) => {
          let identidad = data._id;
          let nft_id = data.nftId;
          let groupingName = data.properties.name;
          let groupingTipo = data.properties.type;
          let Imagen=data.properties.image;
        

          let MyUtility_Nft = {
            identidad: identidad,
            nftId: nft_id,
        
            name: groupingName,
            tipo: groupingTipo,
                imagen: Imagen,
       
          };





          if (groupingName in groupedUtilityNfts) {
            groupedUtilityNfts[groupingName].push(MyUtility_Nft);
          } else {
            groupedUtilityNfts[groupingName] = [MyUtility_Nft];
          }
        });

      setMyUtility_Nfts(prevMyUtilityNfts => {
        const updatedMyUtilityNfts = { ...prevMyUtilityNfts };

        // Agregar la nueva información al objeto groupedUtilityNfts
        Object.keys(groupedUtilityNfts).forEach(groupingName => {
          if (groupingName in updatedMyUtilityNfts) {
            updatedMyUtilityNfts[groupingName] = [
              ...updatedMyUtilityNfts[groupingName],
              ...groupedUtilityNfts[groupingName]
            ];
          } else {
            updatedMyUtilityNfts[groupingName] = groupedUtilityNfts[groupingName];
          }
        });

        // Devolver el estado actualizado
        return updatedMyUtilityNfts;
      });

      } catch (error) {
        console.log(`Error fetching para`, error);
      }
 
  };

  getMyBooster();
}, []);



useEffect(() => {
  const getMyBreeding = async () => {


  
      
      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      };

      // let query = {};
      // query['grouping.type'] = groupingType;
      // query.account = localStorage.getItem("user");

  let query={}
      query.account = localStorage.getItem("user");
      query['properties.type']="breeding";
      
      


    let body = JSON.stringify([
      {
        method: "find",
        jsonrpc: "2.0",
        params: {
          contract: 'nft',
          table: "BHREQTinstances",
          query: query,
          limit: 100,
          offset: 0,
          indexes: [],
        },
        id: 1,
      },
    ]);

      try {
        const response = await axios.post(rpcNode, body, config);
        const data = response.data[0].result;

      
        let groupedUtilityNfts = {};

        data.forEach((data) => {
          let identidad = data._id;
          let nft_id = data.nftId;
          let groupingName = data.properties.name;
          let groupingTipo = data.properties.type;
          let Imagen=data.properties.image;
        

          let MyUtility_Nft = {
            identidad: identidad,
            nftId: nft_id,
        
            name: groupingName,
            tipo: groupingTipo,
            imagen: Imagen,
       
          };





          if (groupingName in groupedUtilityNfts) {
            groupedUtilityNfts[groupingName].push(MyUtility_Nft);
          } else {
            groupedUtilityNfts[groupingName] = [MyUtility_Nft];
          }
        });

      setMyUtility_Nfts(prevMyUtilityNfts => {
        const updatedMyUtilityNfts = { ...prevMyUtilityNfts };

        // Agregar la nueva información al objeto groupedUtilityNfts
        Object.keys(groupedUtilityNfts).forEach(groupingName => {
          if (groupingName in updatedMyUtilityNfts) {
            updatedMyUtilityNfts[groupingName] = [
              ...updatedMyUtilityNfts[groupingName],
              ...groupedUtilityNfts[groupingName]
            ];
          } else {
            updatedMyUtilityNfts[groupingName] = groupedUtilityNfts[groupingName];
          }
        });

        // Devolver el estado actualizado
        return updatedMyUtilityNfts;
      });

      } catch (error) {
        console.log(`Error fetching para`, error);
      }
 
  };

  getMyBreeding();
}, []);






























 const handleOpenM = () => {
    setShowUtilityModal(false);
    setShowMyUtilityModal(true);
  };

  const handleMyItemsClick = () => {
   
    setShowUtilityModal(true);
  };


  return (
    <>
      <div className="wrapp">
        <div className="container">

        

        {MyUtility_Nfts.length === 0 && isLoaded === false ? (
          <></>
        ) : (
         <MyUtilityModal nfts={MyUtility_Nfts} onBuy={handleOpenM} nft_tipo="Tacks"/>

        )}



        </div>
      </div>
    </>
  );
}
