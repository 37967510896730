import React, { useState, useEffect } from "react";
import axios from 'axios';
import "./components/infoballo/InfoBallosModal.css";
import "./components/infoballo/transiciones.css";
import { useTranslation } from 'react-i18next';
import iconCorriendoAndoL from'./../../../../assets/gif/running-horse-horse-racing.gif'
import iconCelebrate from'./../../../../assets/gif/celebrate.gif'
import Icon from './../../../generals/svgiconos/svgiconosenreact.jsx';
import { linkApp } from './../../../generals/configuracion/variablesPublicas.jsx';
import { rpcNode } from './../../../generals/configuracion/variablesPublicas.jsx';

import TrainingModule from'./components/infoballo/trainingmodal/TrainigSelector.jsx';
import FoodSelector from'./components/infoballo/feedmodal/FoodSelector.jsx';  
import ImplementoSelector from'./components/infoballo/implementomodal/ImplementoSelector.jsx';
import CardInfo from './components/infoballo/tarjetacaballo/CardInfo.jsx';
import ResultadosModal from './components/infoballo/resultados/Resultados.jsx'
import UltimasSeisTabla from './components/infoballo/ultimascarreras/Ultimas6.jsx'
import { CSSTransition, TransitionGroup } from 'react-transition-group';


const ModalBallo = ({ children, isOpen, closeModalInf,propsi,selectedId}) => {
   const [showItemsAlimentos, setShowItemsAlimentos] = useState(false);
   const [showItemsImplementos, setShowItemsImplementos] = useState(false);
   const[showItemsEntrenamiento,setShowItemsEntrenamiento]= useState(false)
   const [alimentosConsumidos, setAlimentosConsumidos] = useState([]);
   const [alimentoStatus, setAlimentoStatus] = useState(null);
   const [implementosConsumidos, setImplementosConsumidos] = useState([]);
   const[entrenamientoDisponible,setEntrenamientoDisponible] = useState(false)
   const[entrenamientoGratis,setEntrenamientoGratis] = useState(false)
   const[lastEntrenamiento,setLastEntrenamiento] = useState(null)
   const[entrenamientoRecien,setEntrenamientoRecien] = useState(null)
   const [alimentosPuntos, setAlimentosPuntos] = useState(null);
   const [implementosPuntos, setImplementosPuntos] = useState(null);
   const [implementoStatus, setImplementoStatus] = useState(null);
   const [utilityNfts, setUtilityNfts] = useState([]);
   const[entrenamientoNfts,setEntrenamientoNfts]=useState([])
   //const [implementosNfts, setImplementosNfts] = useState([]);
   const [selectedItem, setSelectedItem] = useState(null);
   const [equinoData, setEquinoData] = useState({});
   const [totalCarreras, setTotalCarreras] = useState(null);
   const [carrerasGanadas, setCarrerasGanadas] = useState(null);
   const [carrerasSecundon, setCarrerasSecundon] = useState(null);
   const [ultimasSeis, setUltimasSeis] = useState({});
   const [mostrarUltimasSeis, setMostrarUltimasSeis] = useState(false);
  
   const[lastRace,setLastRace]=useState(false)
   const[aplicadosNfts,setAplicadosNfts]=useState([])
   const mostrarResultados = () => {
    setMostrarUltimasSeis(!mostrarUltimasSeis);
  };

   const handleModalContainerClicke = (e) => e.stopPropagation();
   const { t } = useTranslation();


const imagenAlimentosUrls = {
  1: '/image/utility_nfts/hay.png',
  2: '/image/utility_nfts/alfafa.png',
  3: '/image/utility_nfts/carrot.png',
  4: '/image/utility_nfts/apple.png',
};









      
      
  



  
 function handleSelectChangeEntrenamientoItem(event) {
    const selectedItem = entrenamientoNfts .find(
      item => item.name === event.target.value
    );

    console.log("selectedItem",selectedItem)
    setSelectedItem(selectedItem);
  }

   function handleSelectChange(event) {
    const selectedItem = utilityNfts.find(
      item => item.name === event.target.value
    );

    console.log("selectedItem",selectedItem)
    setSelectedItem(selectedItem);
  }

// function handleSelectChangeAlimento(event) {
//   console.log(event)
//     const selectedItem = utilityNfts.find(
//       item => item.id === event.target.value
//     );

//     console.log("selectedItem",selectedItem)
//     setSelectedItem(selectedItem);
//   }



 const handleSelectChangeAlimento = (event) => {
    const selectedItemId = parseInt(event.target.value, 10);
    console.log("Selected Item ID:", selectedItemId); // Verificar el valor seleccionado
    const selectedNft = utilityNfts.find((item) => item.id === selectedItemId);
    console.log("Selected NFT:", selectedNft); // Verificar el objeto seleccionado
    setSelectedItem(selectedNft);
  };


function handleAplicarItem1Click() {
const nftId ="" + selectedItem.id;
let memo=`For mint: ${propsi.identidad} : ${selectedItem.name} : ${selectedItem.type}: ${selectedItem.id}`
// console.log("e")
let toUsername="bhr-null"
let username=localStorage.getItem("user");



if (implementoStatus === "pending") {
  alert("Implemento en estado pendiente");
  // console.log("Implemento en estado pendiente");
  throw new Error("Implemento en estado pendiente");
}


const updatedUtilityNfts = utilityNfts.filter(
      item => item.id !== selectedItem.id
    );
 setUtilityNfts(updatedUtilityNfts)   

if (window.hive_keychain) {
  const keychain = window.hive_keychain;
  const customJson = [{
    contractName: 'nft',
    contractAction: 'transfer',
    contractPayload: {
   
      nfts: [
        {
          symbol: 'BHREQT',
          ids: [nftId]
        }
      ],
       to: toUsername,
      memo: memo
    }
  }];

  keychain.requestCustomJson(username, 'ssc-mainnet-hive', 'Active', JSON.stringify(customJson), 'Transfer NFTs', response => {
    // console.log('Response from Hive Keychain:', response);
      axios.post(`${linkApp}/aplicar_nfts`, response)
       .then(res => {
         console.log('Response from server:', res);
         setShowItemsImplementos(false);
         setShowItemsAlimentos(false);
         setImplementoStatus("pending")
         setAlimentoStatus("pending")
         cargarEquinoData();
       
       })
       .catch(error => {
         console.log('Error:', error);
      });
  });
} else {
  alert('You do not have hive keychain installed');
    alert("Implemento en estado pendiente");
}
 setSelectedItem(null);
};


function handleAplicarItemEntrenamiento() {
const nftId ="" + selectedItem.id;
let memo=`For mint: ${propsi.identidad} : ${selectedItem.name} : ${selectedItem.type}: ${selectedItem.id}`
console.log(memo)
// console.log("e")
let toUsername="bhr-magic-null"
let username=localStorage.getItem("user");

if (implementoStatus === "pending") {
  alert("Implemento en estado pendiente");
  // console.log("Implemento en estado pendiente");
  throw new Error("Implemento en estado pendiente");
}


const updatedUtilityNfts = utilityNfts.filter(
      item => item.id !== selectedItem.id
    );
 setUtilityNfts(updatedUtilityNfts)   

if (window.hive_keychain) {
  const keychain = window.hive_keychain;
  const customJson = [{
    contractName: 'nft',
    contractAction: 'transfer',
    contractPayload: {
   
      nfts: [
        {
          symbol: 'BHREQT',
          ids: [nftId]
        }
      ],
       to: toUsername,
      memo: memo
    }
  }];

  keychain.requestCustomJson(username, 'ssc-mainnet-hive', 'Active', JSON.stringify(customJson), 'Transfer NFTs', response => {
    // console.log('Response from Hive Keychain:', response);
      axios.post(`${linkApp}/aplicar_nfts`, response)
       .then(res => {
         console.log('Response from server:', res);
         setShowItemsImplementos(false);
         setShowItemsAlimentos(false);
         setImplementoStatus("pending")
         setAlimentoStatus("pending")
         cargarEquinoData();
       
       })
       .catch(error => {
         console.log('Error:', error);
      });
  });
} else {
  alert('You do not have hive keychain installed');
    alert("Implemento en estado pendiente");
}
 setSelectedItem(null);
};


function esperarQuinceSegundos() {
  return new Promise(resolve => {
    console.log("Esperando 15 segundos...");

    setTimeout(() => {
      console.log("Han pasado 15 segundos.");
      resolve(); // Resuelve la Promesa después de esperar 15 segundos
    }, 300);
  });
}

// Uso de la Promesa con then



// ALIMENTAR CARGA FUNCIONES
function handleClickAlimentar() {
// Llama a la función para iniciar la espera


esperarQuinceSegundos().then(() => {

  console.log("Puedes continuar aquí con tu lógica después de esperar 15 segundos.");
  // Coloca tu código aquí para ejecutar después de esperar 15 segundos.
  if (alimentosConsumidos.length <1 ) {

    Promise.all([
      handleGetUtilityNfts("food", "alfalfa"),
      handleGetUtilityNfts("food", "hay"),
      GetNftsAplicados()
    ]).then(([alfalfaData, hayData]) => {


      const updatedUtilityNfts = [...alfalfaData, ...hayData];
      let idsAplicados=[]

 console.log(typeof(aplicadosNfts))
if (Array.isArray(aplicadosNfts)) {
 idsAplicados = aplicadosNfts.map(obj => obj.Nft_id);
  // Resto del código que utiliza resultado
} else {
  console.error('aplicadosNfts no es un array');
}

const utilityDescontado = updatedUtilityNfts.filter(obj => !idsAplicados.includes(obj.id));

console.log("utilityDescontado",utilityDescontado);

console.log("updatedUtilityNfts",updatedUtilityNfts)
console.log("aplicadosNfts",aplicadosNfts)
setUtilityNfts(utilityDescontado);
setShowItemsAlimentos(!showItemsAlimentos);
setShowItemsImplementos(false);});
  } else {
    Promise.all([
      handleGetUtilityNfts("food", "apple"),
      handleGetUtilityNfts("food", "carrot"),
      GetNftsAplicados()
    ]).then(([appleData, carrotData]) => {
      const updatedUtilityNfts = [...appleData, ...carrotData];
      setUtilityNfts(updatedUtilityNfts);



    setShowItemsImplementos(false);
    setShowItemsEntrenamiento(false);

       setShowItemsAlimentos(!showItemsAlimentos);
    });
  }
});



}

// AGREGAR IMPLEMENTO CARGA FUNCIONES
function handleClickAgregarImplemento(){
  if(implementoStatus!=="pending"){
// console.log("implementosConsumidos", implementosConsumidos);
if (implementosConsumidos && Object.keys(implementosConsumidos).length <=4) {
  Promise.all([
    handleGetUtilityNfts("tack")
  ]).then(([implementosData]) => {
    const updatedUtilityNfts = [...implementosData];
    setUtilityNfts(updatedUtilityNfts);
    
    setShowItemsAlimentos(false);
    setShowItemsEntrenamiento(false);
    setShowItemsImplementos(!showItemsImplementos);
  });
}
}else{
  alert("espera que se procese la transaccion")
  console.log("espera que se procese la transaccion")
}



}








// AGREGAR IMPLEMENTO CARGA FUNCIONES
function handleClickEntrenamiento(){
  if(entrenamientoGratis!==2){
console.log("implementosConsumidos", implementosConsumidos);
if (implementosConsumidos && Object.keys(implementosConsumidos).length <=4) {
  Promise.all([
    handleGetEntrenamientoNfts("recover")
  ]).then(([implementosData]) => {
    const updatedUtilityNfts = [...implementosData];
    setEntrenamientoNfts(updatedUtilityNfts);
    
    setShowItemsAlimentos(false);
    setShowItemsImplementos(false);
    setShowItemsEntrenamiento(!showItemsEntrenamiento);
  });
}
}else{
 
    Promise.all([
    handleGetEntrenamientoNfts("recover")
  ]).then(([implementosData]) => {
    const updatedUtilityNfts = [...implementosData];
    setEntrenamientoNfts(updatedUtilityNfts);
    
    setShowItemsAlimentos(false);
    setShowItemsImplementos(false);
    setShowItemsEntrenamiento(!showItemsEntrenamiento);
  });
}



}



// AGREGAR IMPLEMENTO CARGA FUNCIONES
function handleClickEntrenar(){
let equino=propsi.identidad;
  if(entrenamientoGratis===1){

  axios.post(`${linkApp}/entrenar?equineId=${equino}&entrenamientoDisponible=${entrenamientoDisponible}&g=${entrenamientoGratis}`)
       .then(res => {
         setEntrenamientoRecien(res.data.body[0])
         setShowItemsImplementos(false);
         setShowItemsAlimentos(false);
       
       })
       .catch(error => {
         console.log('Error:', error);
      });
}else if (entrenamientoGratis===0){
  
let symbol="BHR";
let amount=Number(entrenamientoDisponible)/10; 
    amount=amount.toFixed(3);
let memo=`Training of ${entrenamientoDisponible} points for horse ${equino}  race ${lastRace} `
let toUsername="bhr-train";
let username=localStorage.getItem("user");

if (window.hive_keychain) {
  const keychain = window.hive_keychain;
  keychain.requestSendToken(username, toUsername, amount, memo, symbol, (response) => {
   
    
    axios.post(`${linkApp}/entrenar`,response) 
      .then(res => {  
        setEntrenamientoRecien(res.data.body[0])
         setShowItemsImplementos(false);
         setShowItemsAlimentos(false);

     })
      .catch(error => {
        console.log("error DE post axio",error)
      });
  });
} else {
  alert('You do not have hive keychain installed');
}






}else if(entrenamientoGratis===5){
  alert("")
}

}



// AGREGAR IMPLEMENTO CARGA FUNCIONES
function GetNftsAplicados(){
console.log("GetNftsAplicados")
let username=localStorage.getItem("user");

    
    axios.get(`${linkApp}/aplicar_nfts?account=${username}`) 
      .then(res => {  
        console.log("SERVER :",res.data.body)
         setAplicadosNfts(res.data.body)

     })
      .catch(error => {
        console.log("error DE post axio",error)
      });







}



















const handleGetUtilityNfts = async (busqueda, name) => {
  try {
    let query = {};
    query.account = localStorage.getItem("user");
    query['properties.type'] = busqueda;
   if (name) query['properties.name'] = name;

    const response = await axios.post(
      rpcNode,
      {
        jsonrpc: '2.0',
        method: 'find',
        params: {
          contract: 'nft',
          table: 'BHREQTinstances',
          query: query,
          limit: 100,
          offset: 0,
          indexes: [],
        },
        id: 1,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      }
    );

    if (response.data && response.data.result) {
      const data = response.data.result;
      const parsedData = data.map((item) => {
        //console.log("ITEM",item)
        return {
          id: item._id,
          type:item.properties.type,
          name: item.properties.name,

        };
      });

      return parsedData;
    } else {
      console.log('No se encontraron datos en la respuesta.');
      return [];
    }
  } catch (error) {
    console.log('Error fetching utility NFTs:', error);
    return [];
  }
};

       
const handleGetEntrenamientoNfts = async (busqueda, name) => {
  try {
    let query = {};
    query.account = localStorage.getItem("user");
    query['properties.type'] = busqueda;
   if (name) query['properties.name'] = name;

    const response = await axios.post(
      rpcNode,
      {
        jsonrpc: '2.0',
        method: 'find',
        params: {
          contract: 'nft',
          table: 'BHREQTinstances',
          query: query,
          limit: 100,
          offset: 0,
          indexes: [],
        },
        id: 1,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      }
    );

    if (response.data && response.data.result) {
      const data = response.data.result;
      const parsedData = data.map((item) => {
        //console.log("ITEM",item)
        return {
          id: item._id,
          type:item.properties.type,
          name: item.properties.name,

        };
      });

      return parsedData;
    } else {
      console.log('No se encontraron datos en la respuesta.');
      return [];
    }
  } catch (error) {
    console.log('Error fetching utility NFTs:', error);
    return [];
  }
};




 console.log("utility_nfts",utilityNfts)



  

function cargarEquinoData() {
  if (selectedId) {
    //${link}
    axios.get(`${linkApp}/equino?equineId=${propsi.identidad}`)
      .then(response => {
                setEquinoData(response.data.body[0]);
        //console.log("DATA DEL EQUINO DESDE LA BASE",response.data.body[0])
        setTotalCarreras(response.data.body[0].carreras.length)
        //console.log("DATA DEL EQUINO DESDE LA BASE legth",response.data.body[0].carreras.length)
        setUltimasSeis(response.data.body[0].carreras.slice(-6).reverse());
        setCarrerasGanadas(response.data.body[0].carreras_win.length)
        setCarrerasSecundon(response.data.body[0].carreras_second.length)
        setAlimentosConsumidos(response.data.body[0].alimentos)
      
        setImplementosConsumidos(response.data.body[0]?.implementos ?? []);
        setEntrenamientoDisponible(response.data.body[0]?.opcion_entrenamiento ?? []);
        setEntrenamientoGratis(response.data.body[0]?.entrenamiento_gratis ?? []);
        setLastEntrenamiento(response.data.body[0]?.lastTrain ?? []);
        setAlimentosPuntos(response.data.body[0]?.alimentos_ptos ?? 0);
        setImplementosPuntos(response.data.body[0]?.implementos_ptos ?? 0);
        setAlimentoStatus(response.data.body[0]?.alimentos_status ?? "free");
        setImplementoStatus(response.data.body[0]?.implementos_status ?? "free");
        setLastRace(response.data.body[0]?.lastRace ?? 0);

      })
      .catch(error => {
        console.error(error);
      });
  }
}

useEffect(() => {
  cargarEquinoData();
}, []);
  return (
    <article className={`modal-info-b ${isOpen && "is-open"}`} onClick={closeModalInf}>
      <div className="modal-container-info-b" onClick={handleModalContainerClicke}>
         
         {/* <div className="container-info">*/}
      




 <TransitionGroup>
        {mostrarUltimasSeis ? (
          <CSSTransition key="ultimasSeis" timeout={300} classNames="fade">
            <div className="races_runs-pantallantigua">
              {ultimasSeis.length > 0 ? (
                <UltimasSeisTabla ultimasSeis={ultimasSeis} />
              ) : (
                <p>There are no races available.</p>
              )}
            </div>
          </CSSTransition>
        ) : (
          <CSSTransition key="cardInfo" timeout={300} classNames="fade">
            <CardInfo equinoData={equinoData} propsi={propsi} />
          </CSSTransition>
        )}
      </TransitionGroup>

      






          <div className="card-info-c">
          <div className="content"> 
             <ResultadosModal 
                mostrarResultados={mostrarResultados}
                mostrarUltimasSeis={mostrarUltimasSeis}
                ultimasSeis={ultimasSeis}
                // onClose={cerrarModal} 
                carrerasGanadas={carrerasGanadas} 
                carrerasSecundon={carrerasSecundon} 
                totalCarreras={totalCarreras} 
                t={t} 
            />



{/*
            {mostrarUltimasSeis && (
                <div className="races_runs-pantallantigua">
                    {ultimasSeis.length > 0 ? (
                      <UltimasSeisTabla ultimasSeis={ultimasSeis} />
                    ) : (
                        <p>There are no races available.</p>
                    )}
                </div>
            )}
 

 <CardInfo equinoData={equinoData} propsi={propsi} />
*/}






  <FoodSelector
        showItemsAlimentos={showItemsAlimentos}
        alimentosConsumidos={alimentosConsumidos}
        imagenAlimentosUrls={imagenAlimentosUrls}
        alimentosPuntos={alimentosPuntos || 0}
        alimentoStatus={alimentoStatus}
        selectedItem={selectedItem}
        handleSelectChangeAlimento={handleSelectChangeAlimento}
        handleAplicarItem1Click={handleAplicarItem1Click}
        utilityNfts={utilityNfts}
      />


  <ImplementoSelector
        showItemsImplementos={showItemsImplementos}
        implementosConsumidos={implementosConsumidos}
        implementosPuntos={implementosPuntos}
        implementostatus={implementoStatus}
        selectedItem={selectedItem}
        handleSelectChange={handleSelectChange}
        handleAplicarItem1Click={handleAplicarItem1Click}
        utilityNfts={utilityNfts}
      />









{showItemsEntrenamiento && (

    <TrainingModule
      showItemsEntrenamiento={showItemsEntrenamiento} 
      entrenamientoRecien={entrenamientoRecien}
      entrenamientoDisponible={entrenamientoDisponible}
      entrenamientoGratis={entrenamientoGratis}
      lastEntrenamiento={lastEntrenamiento}
      iconCelebrate={iconCelebrate}
      iconCorriendoAndoL={iconCorriendoAndoL}
      entrenamientoNfts={entrenamientoNfts}
      selectedItem={selectedItem}
      handleSelectChangeEntrenamientoItem={handleSelectChangeEntrenamientoItem} 
      handleAplicarItemEntrenamiento={handleAplicarItemEntrenamiento}
      mint={propsi.identidad}
      handleClickEntrenar={handleClickEntrenar}
      
    />
)}






<div className="actions">
<input type="submit" name="background"  value={t('info.alimentar')} onClick={handleClickAlimentar}/>
<input type="submit" name="background"  value={t('info.implementos')} onClick={handleClickAgregarImplemento}/>
<input type="submit" name="background"  value={t('info.entrenar')} onClick={handleClickEntrenamiento}/>
</div>


  
 
  
  </div></div>
        {children}
      
      </div>
    </article>
  );
};

export default ModalBallo;
