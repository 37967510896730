// src/hooks/useCheckImageExistence.js
import { useState, useEffect } from 'react';

const useCheckImageExistence = (carouselData, carouselIndex, registradosCarouselIndex) => {
  const [imagenRegistradoSrc, setImagenRegistradoSrc] = useState(null);

  useEffect(() => {
    const checkImageExistence = () => {
      const imagenRegistrado = `./image/equinos/${carouselData[carouselIndex]?.registrados[registradosCarouselIndex]}.png`;
      const imagenPredeterminada = `./image/equinos/000.png`;

      const img = new Image();
      img.onload = () => {
        setImagenRegistradoSrc(imagenRegistrado);
      };
      img.onerror = () => {
        setImagenRegistradoSrc(imagenPredeterminada);
      };
      img.src = imagenRegistrado;
    };

    if (carouselData && carouselData.length > 0 && carouselData[carouselIndex]?.registrados) {
      checkImageExistence();
    } else {
      setImagenRegistradoSrc(null); // Clear the image if carouselData is invalid
    }
  }, [carouselData, carouselIndex, registradosCarouselIndex]);

  return { imagenRegistradoSrc };
};

export default useCheckImageExistence;
