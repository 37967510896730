import React from 'react';
import RaceOpenBet from '../pantallaOpenBets/RaceOpenBet';

const RightScreen = ({ carouselData, carouselIndex, t }) => {
  if (!carouselData || carouselData.length === 0) {
    return <div>Cargando datos...</div>; // o null si prefieres no mostrar nada
  }

  return (
    <div className="screen right-screen">
      <div className="neon-border-pixel">
        <RaceOpenBet
          carouselData={carouselData} // Pasar todo combinedData
          carouselIndex={carouselIndex}
          t={t}
        />
      </div>
    </div>
  );
};

export default RightScreen;
