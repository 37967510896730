import React, { useState, useEffect } from "react";
import axios from 'axios';
import './food.css'
import Loader from "./../../../../generals/loders/Loader.jsx";
import UtilityModal from "./food/Utility_Modal";
import MyUtilityModal from "./food/MyUtility_Modal";
import { handleBuyOrder, handleCancelOrder } from './food/handles.jsx';
import useMarketNfts from '../../../../../hooks/useMarketNfts'; // Import the generic hook
import useMyMarketNfts from '../../../../../hooks/useMyMarketNfts'; // Import the generic hook


export default function Food() {
  const [MyUtility_Nfts, setMyUtility_Nfts] = useState([]);
  const [showUtilityModal, setShowUtilityModal] = useState(true);
  const [showMyUtilityModal, setShowMyUtilityModal] = useState(false);
  const { nfts: utility_Nfts, isLoaded} = useMarketNfts("equine");
 const { nfts: myUtility_Nfts, isLoaded: isMyLoaded} = useMyMarketNfts("equine");




 const handleMarketClick = () => {
    setShowUtilityModal(true);
    setShowMyUtilityModal(false);
  };

  const handleMyItemsClick = () => {
    setShowMyUtilityModal(true);
    setShowUtilityModal(false);
  };

  return (
    <div className="wrapp">
      <div className="container-market-alimentos">
        {utility_Nfts.length === 0 && isLoaded === false ? (
          <Loader />
        ) : (
          <button onClick={handleMarketClick} className="Nav-Market"><h1>Market</h1></button>
        )}

        {myUtility_Nfts.length === 0 && isLoaded === false ? (
          <></>
        ) : (
          <button onClick={handleMyItemsClick} className="Nav-Market"><h1>Your Items</h1></button>
        )}

        {showUtilityModal && (
          <UtilityModal nfts={utility_Nfts} onBuy={handleBuyOrder} />
        )}

        {showMyUtilityModal && (
          <MyUtilityModal nfts={myUtility_Nfts} onBuy={handleCancelOrder} />
        )}
      </div>
    </div>
  );
}

